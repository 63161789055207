<template>
  <div class="pathway-create-page">
    <div class="hero">
      <h1 class="title is-1">Create a Pathway</h1>
    </div>
    <div class="container">
      <b-steps
        v-model="activeStep"
        :animated="true"
        :rounded="true"
        :has-navigation="false"
        size="is-large"
        class="onboarding-header"
      >
        <template slot="navigation" slot-scope="{ previous, next }">
          <b-button
            outlined
            size="is-large"
            type="is-primary"
            icon-pack="fas"
            icon-left="backward"
            :disabled="previous.disabled"
            @click.prevent="previous.action"
          >
            Previous
          </b-button>
          <b-button
            outlined
            size="is-large"
            type="is-primary"
            icon-pack="fas"
            icon-right="forward"
            :disabled="next.disabled"
            @click.prevent="next.action"
          >
            Next
          </b-button>
        </template>
        <b-step-item label="Define Your Pathway" icon="fa fas fa-road">
          <h2 class="title is-3">
            Playlist Title
          </h2>
          <b-field>
            <b-input
              size="is-medium"
              type="text"
              placeholder="Playlist Title"
              v-model="playlistTitle"
            ></b-input>
          </b-field>
          <h3 class="title is-3">Expertise Level</h3>
          <div class="options">
            <b-field>
              <b-radio-button
                size="is-large"
                v-model="playlistLevel"
                native-value="exploring"
              >
                <span class="icon is-small">
                  <i class="fas fa-laptop"></i>
                </span>
                <span>
                  Exploring
                </span>
              </b-radio-button>
            </b-field>
            <b-field>
              <b-radio-button
                size="is-large"
                v-model="playlistLevel"
                native-value="practicing"
              >
                <span class="icon is-small">
                  <i class="fas fa-laptop"></i>
                </span>
                <span>
                  Practicing
                </span>
              </b-radio-button>
            </b-field>
            <b-field>
              <b-radio-button
                size="is-large"
                v-model="playlistLevel"
                native-value="pro"
              >
                <span class="icon is-small">
                  <i class="fas fa-laptop"></i>
                </span>
                <span>
                  Pro
                </span>
              </b-radio-button>
            </b-field>
          </div>
          <h3 class="title is-3">Skill Set</h3>
          <div class="options">
            <b-field>
              <b-radio-button
                size="is-large"
                v-model="careerChoice"
                native-value="frontend"
              >
                <span class="icon is-small">
                  <i class="fas fa-laptop"></i>
                </span>
                <span>
                  Front End Engineering
                </span>
              </b-radio-button>
            </b-field>
            <b-field>
              <b-radio-button
                size="is-large"
                v-model="careerChoice"
                native-value="practicing"
              >
                <span class="icon is-small">
                  <i class="fas fa-laptop"></i>
                </span>
                <span>
                  Back end Engineering
                </span>
              </b-radio-button>
            </b-field>
            <b-field>
              <b-radio-button
                size="is-large"
                v-model="careerChoice"
                native-value="practicing"
              >
                <span class="icon is-small">
                  <i class="fas fa-laptop"></i>
                </span>
                <span>
                  Mobile Engineering
                </span>
              </b-radio-button>
            </b-field>

            <b-field>
              <b-radio-button
                size="is-large"
                v-model="careerChoice"
                native-value="ui/ux"
              >
                <span class="icon is-small">
                  <i class="fas fa-laptop"></i>
                </span>
                <span>
                  UI/UX Design
                </span>
              </b-radio-button>
            </b-field>
          </div>
        </b-step-item>
        <b-step-item label="Add Your Resources" icon="fa fas fa-gem">
          <div class="columns">
            <div class="column">
              <h2 class="title is-2">Add A New Resource</h2>
              <b-field>
                <b-input
                  size="is-medium"
                  type="text"
                  placeholder="New Resource URL"
                  v-model="newResourceURL"
                ></b-input>
              </b-field>
              <b-field>
                <b-input
                  size="is-medium"
                  type="text"
                  placeholder="Title"
                  v-model="newResourceTitle"
                ></b-input>
              </b-field>
              <b-field>
                <b-input
                  size="is-medium"
                  type="description"
                  placeholder="Description"
                  v-model="newResourceDescription"
                ></b-input>
              </b-field>
              <div class="options">
                <b-field>
                  <b-radio-button
                    size="is-large"
                    v-model="newResourceType"
                    native-value="Text reference"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-laptop"></i>
                    </span>
                    <span>
                      Text Reference
                    </span>
                  </b-radio-button>
                </b-field>

                <b-field>
                  <b-radio-button
                    size="is-large"
                    v-model="newResourceType"
                    native-value="Text theory"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-laptop"></i>
                    </span>
                    <span>
                      Text Theory
                    </span>
                  </b-radio-button>
                </b-field>

                <b-field>
                  <b-radio-button
                    size="is-large"
                    v-model="newResourceType"
                    native-value="Text guide"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-laptop"></i>
                    </span>
                    <span>
                      Text Guide
                    </span>
                  </b-radio-button>
                </b-field>
                <b-field>
                  <b-radio-button
                    size="is-large"
                    v-model="newResourceType"
                    native-value="Hands-On Video"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-laptop"></i>
                    </span>
                    <span>
                      Hands On Video
                    </span>
                  </b-radio-button>
                </b-field>
                <b-field>
                  <b-radio-button
                    size="is-large"
                    v-model="newResourceType"
                    native-value="Lecture video"
                  >
                    <span class="icon is-small">
                      <i class="fas fa-laptop"></i>
                    </span>
                    <span>
                      Lecture Video
                    </span>
                  </b-radio-button>
                </b-field>
              </div>
              <button
                class="button is-fullwidth is-primary is-medium"
                @click="addResource"
              >
                <span>
                  Add Resource
                </span>
              </button>
            </div>
            <div class="column" v-if="resources.length > 0">
              <h2 class="title is-2">Current Pathway</h2>
              <draggable v-model="resources" class="resource-box">
                <transition-group>
                  <div
                    v-for="(resource, i) in resources"
                    class="resource-row columns is-mobile"
                    :key="resource.id"
                  >
                    <div class="column">
                      <div class="title is-3">{{ i + 1 }}</div>
                    </div>

                    <div class="column">
                      <div class="title is-3">{{ resource.title }}</div>
                    </div>
                    <div class="column">
                      <div>{{ resource.caption }}</div>
                    </div>
                    <div class="column">
                      <div>{{ resource.resourceType }}</div>
                    </div>
                  </div>
                </transition-group>
              </draggable>

              <button
                class="button is-fullwidth is-primary is-medium"
                @click="createPathway"
              >
                <span>
                  Create Pathway
                </span>
              </button>
            </div>
          </div>
        </b-step-item>
      </b-steps>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/_variables.scss";

.pathway-create-page {
  min-height: 100vh;

  .title {
    color: white;
  }

  .options {
    .field {
      display: inline-flex;
      align-items: center;
      margin-right: 1rem;
    }
  }

  .resource-row {
    padding: 0.5rem 0;
  }

  .resource-box {
    max-height: 24.4rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
</style>
<script>
import draggable from "vuedraggable";

export default {
  name: "PathwayCreate",
  components: {
    draggable,
  },
  data() {
    return {
      activeStep: 0,
      playlistTitle: "",
      playlistLevel: "",
      careerChoice: "",
      newResourceURL: "",
      newResourceDescription: "",
      newResourceTitle: "",
      newResourceType: "",
      resources: [],
    };
  },
  methods: {
    addResource() {
      const resourceNew = this.resources;
      resourceNew.push({
        url: this.newResourceURL,
        caption: this.newResourceDescription,
        title: this.newResourceTitle,
        resourceType: this.newResourceType,
        id: this.resources.length + 1,
        name: this.newResourceTitle,
      });

      this.resources = resourceNew;
      this.newResourceTitle = "";
      this.newResourceURL = "";
      this.newResourceDescription = "";
    },
    createPathway() {
      const newPathway = {
        title: this.playlistTitle,
        level: this.playlistLevel,
        field: this.careerChoice,
        resources: this.resources,
      };
      console.log(newPathway);
    },
  },
};
</script>
